<template>
    <div class="content-page">
        <div class="content-nav">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item :to="{ name: 'home_activity_mode' }">分类管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{infoForm._id ? '编辑分类' : '添加分类'}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        
        <div class="content-main">
            <div class="form-table-box" :class="infoForm._id ?'sline':''">
                <el-form  :rules="infoRules" :model="infoForm" label-width="80px">
                    <el-form-item label="分类名" v-if="!infoForm._id" >
                        <el-input size="small" v-model="infoForm.activity_name" placeholder="分类名"></el-input>
                      
                    </el-form-item>
                    
                    <el-form-item label="分类名" v-else>
                        <el-col :span="4">
                            <el-input size="small" v-model="infoForm.activity_name" @change="handlerActivityChange" placeholder="输入分类名称"></el-input>
                          </el-col>
                          <el-col :span="1" v-if="nameChange">
                            <el-button type="primary" @click="updateActivityName"  size="small">更新</el-button>
                          </el-col>
                    </el-form-item>

                    <el-form-item label="主题颜色" prop="title_color" >
                        <el-col :span="4">
                            <el-input size="small" readonly="readonly" v-model="infoForm.title_color" placeholder="点击右边选取颜色"></el-input>
                        </el-col>
                        <el-col :span="1">
                            <el-color-picker v-model="infoForm.title_color" @change="handlerColorChange" size="medium"></el-color-picker>
                        </el-col>
                        <el-col :span="1" v-if="colorChange">
                            <el-button type="primary" @click="updateColor"  size="small">更新</el-button>
                        </el-col>
                    </el-form-item>
                    
                    <el-form-item v-if="!infoForm._id">
                        <el-button type="primary" size="small" @click="onSubmitNewActivity">添加</el-button>
                    </el-form-item>
                    
                </el-form>
                
            </div>

            <div class="form-table-box"  v-if="infoForm._id">
                <div class="operation-nav">
                    <router-link to="/dashboard/add_goods_activity">
                        <el-button type="primary" icon="plus" size="small">添加商品</el-button>
                    </router-link>
                </div>
                <el-table :data="tableData" style="width: 100%" stripe>
                    <el-table-column prop="goods_url" label="分类商品封面" width="180">
                        <template scope="scope">
                            <img :src="scope.row.goods_url" alt="" style="width: 60px;height: 60px">
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_name" label="商品名称"></el-table-column>

                    <el-table-column label="操作">
                        <template scope="scope">
                            <el-button size="small" @click="handleRowEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button size="small" plain type="danger" @click="handleRowDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

        </div>
    </div>
</template>

<script>
    import api from '@/config/api';
    import $ from 'jquery'
    export default {
        data() {
            return {
                root: '',
                qiniuZone:'',
                isActivityChange:false,
                picData: {
                    token: ''
                },
                uploaderHeader: {
                    'LuoShao-Token': localStorage.getItem('token') || '',
                },
                infoForm: {
                    _id:"",
                    activity_name:"",
                    title_color:"#409EFF"
                    // is_index: false,
                },
                nameChange:false,
                colorChange:false,
                tableData:[],
                infoRules: {
                    goods_name: [
                        {required: true, message: '请商品名称', trigger: 'blur'},
                    ],
                    goods_url: [
                        {required: true, message: '请选择商品轮播图', trigger: 'blur'},
                    ],
                },
                activity_id:''
            }
        },
        methods: {
            handlerActivityChange()
            {
                this.nameChange = true;
            },
            handlerColorChange()
            {
                this.colorChange = true;
            },
            updateActivityName()
            {

                var that = this;
                this.axios.post('home/updateActivityName',
                            {
                                id:this.infoForm._id,
                                activity_name: this.infoForm.activity_name
                            }).then((response) => {
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功'
                                });
                                that.nameChange = false;
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '创建失败'
                                })
                            }
                        });
                
            },
            updateColor()
            {
                var that = this;
                this.axios.post('home/updateActivityColor',
                            {
                                id:this.infoForm._id,
                                title_color: this.infoForm.title_color
                            }).then((response) => {
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功'
                                });
                                that.colorChange = false;
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '创建失败'
                                })
                            }
                        });
            },
            onSubmitNewActivity() {
                var activityinfo = {
                    activity_name:this.infoForm.activity_name,
                    title_color:this.infoForm.title_color
                };
                var that = this;
                this.axios.post('home/addActivity',
                            {
                                activityinfo: activityinfo
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '创建成功'
                                });
                                
                                that.infoForm = response.data.data;
                                localStorage.setItem('select_activity_id', that.infoForm._id);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '创建失败'
                                })
                            }
                        })
            },
            handleRowEdit(index, row) {
                this.$router.push({name: 'add_goods_activity', query: {id: row._id,goodsinfo:this.tableData[index]}});
            },
            handleRowDelete(index, row) {
                let that = this;
                this.$confirm('确定要删除该商品?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                console.log("row._id is " + row._id);
                console.log("row._id is " + that.activity_id);

                // that.axios.post('home/removeGoodsFromActivity', {id: that.activity_id,goods_id:row_id}).then((response) => {
                //     if (response.data.code > 0) {
                //         that.$message({
                //             type: 'success',
                //             message: '删除成功!'
                //         });
                //         that.getActivityList();
                //     }
                // })
                var params={
                    id: that.activity_id,
                    goods_id:row._id
                }
                that.axios.post('home/removeGoodsFromActivity', params ).then((response) => {
                    if (response.data.code > 0) {
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        that.getActivityInfo();
                    }
                })
                
                }).catch(() => {

                });
            },
            getQiniuToken() {
                let that = this
                this.axios.get('qiniu/getQiniuToken').then((response) => {
                    let resInfo = response.data.data;
                    //console.log(resInfo.token);
                    that.picData.token = resInfo.token;
                    that.url = resInfo.url;
                })
            },
            getActivityInfo()
            {
                let that = this
                console.log("this.infoForm._id again ");
                console.log(this.infoForm._id);
                this.axios.get('home/getActivity',{
                    params: {
                        id: that.infoForm._id
                    }
                }).then((response) => {
                    console.log("getActivityInfo is back !");
                    console.log(response.data.data);
                    if(response.data.code>0)
                    {
                        this.infoForm = response.data.data;
                        this.tableData = this.infoForm.bind_goods;
                    }
                })
            },
            goBackPage() {
                this.$router.go(-1);
            }
        },
        components: {
        },
        computed: {
        },
        mounted() {

            var select_activity_id  = localStorage.getItem('select_activity_id') || ''; 
            console.log("select_activity_id is ");
            console.log(select_activity_id);
            if(!!select_activity_id)
            {
                this.infoForm._id = select_activity_id ;

                if(!!this.infoForm._id)
                {
                    console.log(this.infoForm._id);
                    this.getActivityInfo();
                }
                this.activity_id = select_activity_id;
            }
            
            
            this.getQiniuToken();
            //    this.getAllSpecification();
            if (this.infoForm._id > 0) {
            //        this.getGalleryList();
            }
            this.root = api.rootUrl;
            this.qiniuZone = api.qiniu;
        },
    }

</script>

<style scoped>
    /* .edit_container{ */
    /*.avatar-uploader .el-upload {*/
    /*display: none;*/
    /*}*/

    .sline{
        border-bottom: #8c939d solid 1px;
        margin-bottom: 10px;
    }
    .close-i{
		position: absolute;
		top:-8px;
		right: -9px;
		display: none;
		cursor: pointer;
		font-size: 18px;
		background-color: white;
		border-radius: 10px;
	}
	.newtag{
		position: relative;
		cursor: text;
		overflow: visible;
		display: inline-block;
		padding: 0;	
	}
    .addtag
    {
        margin-left: 10px;
    }
	.newtag-content{
		margin: 7px 15px; 
	}
    .dialog-header {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .dialog-header .value {
        width: 150px;
        margin-right: 14px;
    }

    .input-wrap .el-input {
        width: 200px;
        float: left;
        margin: 0 20px 20px 0;
    }

    .input-wrap .el-input input {
        background-color: #fff !important;
        color: #233445 !important;
    }

    .specFormDialig .el-input {
        width: 150px;
        margin-right: 10px;
    }

    .el-select-class {
        width: 200px;
        margin-right: 20px;
    }

    .sepc-form {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .spec-form-wrap {
        margin-top: 0 !important;
    }

    .add-spec {
        margin-top: 10px;
    }

    .spec-form-wrap .header {
        display: flex;
        justify-content: flex-start;
    }

    .spec-form-wrap .header .l {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    /*.sepc-form div{*/
    /*margin-left: 0;*/
    /*}*/

    .float-right {
        float: right;
    }

    .sepc-form .el-input {
        width: 200px;
        margin-right: 20px;
    }

    .marginTop20 {
        margin-top: 20px;
    }

    .checkbox-wrap .checkbox-list {
        float: left;
        margin-right: 20px;
    }

    .upload_ad{
        display: none;
    }
    .upload_ad .el-upload--picture-card {
        display: none;
    }

    .ql-container {
        min-height: 200px;
        max-height: 400px;
        overflow-y: auto;
    }

    .image-uploader-diy {
        /*height: 200px;*/
        position: relative;
    }

    /*.dele-list-pic {*/
        /*position: absolute;*/
        /*left: 380px;*/
        /*top: 0px;*/
    /*}*/

    .image-uploader-diy .el-upload {
        border: 1px solid #d9d9d9;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .image-uploader-diy .el-upload:hover {
        border-color: #20a0ff;
    }

    .image-uploader-diy .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }

    .image-uploader-diy .image-show {
        min-width: 105px;
        height: 105px;
        display: block;
    }

    .image-uploader-diy .new-image-uploader {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-show {
        width: 165px;
        height: 105px;
        display: block;
    }

    .item-url-image-fuzhu .el-input {
        width: 260px;
    }

    .hidden {
        display: none;
    }
</style>
